<template>
  <el-table :data="walletConfig" style="width: 100%">
    <el-table-column align="center" label="Name" prop="name"> </el-table-column>
    <el-table-column align="center" label="状态">
      <template slot-scope="scope">
        <el-tag v-if="+scope.row.value == 1">关闭</el-tag>
        <el-tag type="success" v-if="+scope.row.value !== 1">开启</el-tag>
      </template>
    </el-table-column>
    <el-table-column align="center" label="Action">
      <template slot-scope="scope">
        <el-button
          v-if="+scope.row.value == 1"
          size="mini"
          @click="handleEdit(scope.row)"
          >开启</el-button
        >
        <el-button
          v-if="+scope.row.value !== 1"
          size="mini"
          type="danger"
          @click="handleEdit(scope.row)"
          >关闭</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapGetters } from "vuex";
import { setWalletConfig } from "@/api/configs";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["walletConfig"]),
  },
  watch: {
    categoryType() {
      this.getWalletConfig();
    },
  },
  mounted() {
    this.getWalletConfig();
  },
  methods: {
    getWalletConfig() {
      this.$store.dispatch("configs/getWalletConfig");
    },
    handleEdit(row) {
      let postData = {};
      postData.code = row.code;
      postData.action = +row.value == 1 ? 0 : 1;
      setWalletConfig(postData).then((data) => {
        if (+data.data.code) {
          this.$message({
            message: data.data.msg,
            duration: 3000,
            type: "error",
          });
        } else {
          this.$message({
            message: "编辑成功",
            duration: 3000,
            type: "success",
          });
        }

        this.getWalletConfig();
      });
    },
  },
};
</script>
